import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import './App.css';

function App() {
  const [minutes, setMinutes] = useState('');
  const [result, setResult] = useState('');
  const [shareableLink, setShareableLink] = useState('');

  const handleInputChange = (event) => {
    setMinutes(event.target.value);
  };

  const handleCalculateClick = () => {
    const multiplier = Math.floor(Math.random() * 30) + 1;
    const calculationResult = minutes * multiplier;
    setResult(calculationResult);

    // Create a shareable link with the result and the input time
    const link = `${window.location.href}?result=${calculationResult}&minutes=${minutes}`;
    setShareableLink(link);
  };

  const [isShared, setIsShared] = useState(false);

  const handleShareClick = () => {
    // Copy the shareable link to the clipboard
    navigator.clipboard.writeText(shareableLink)
      .then(() => {
        console.log('Link copied to clipboard');
        setIsShared(true);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  // Check for a result and minutes query parameters when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resultParam = urlParams.get('result');
    const minutesParam = urlParams.get('minutes');

    if (resultParam) {
      setResult(resultParam);
    }

    if (minutesParam) {
      setMinutes(minutesParam);
    }

    if (resultParam && minutesParam) {
      setIsShared(true);
    }
  }, []);

  const handleTryAgainClick = () => {
    setIsShared(false);
    setMinutes('');
    setResult('');
  };

  return (
    <div className="App">
      {isShared && <Confetti />}
      <header className="App-header">
        {!isShared && <h1>Hoe lang tot dat Thijmen er is?</h1>}
        {!isShared && (
          <>
            <input
              className="input-field"
              type="text"
              value={minutes}
              onChange={handleInputChange}
              placeholder="Aantal minuten..."
            />
            <button onClick={handleCalculateClick} className="calculate-button">Start Thijmer</button>
            <button onClick={handleShareClick} className="share-button">Share Link</button>
          </>
        )}
        {minutes && <p>Thijmen geeft aan er in {minutes} minuten te zijn.</p>}
        {result && <p>Dit worden er waarschijnlijk {result}</p>}
        {isShared && <button onClick={handleTryAgainClick} className="try-again-button">Opnieuw</button>}
      </header>
    </div>
  );
}

export default App;